<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import '../../theme.css';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

:root {
  --color-link: #3a71fb;
}

* {
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

#full {
  background: #e1e6ea;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
}

.title {
  margin-bottom: 5px;
}

.subtitle {
  line-height: 24px;
}

/* Animations */

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all ease-in-out 0.25s;
}

.modal-fade-enter-from,
.modal-fade-leave-to  {
  opacity: 0;
}

.modal-pop-enter-active,
.modal-pop-leave-active {
  transition: all ease-in-out 0.25s;
  transform: none;
  opacity: 1;
}

.modal-pop-enter-from,
.modal-pop-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.modal-appear-enter-active,
.modal-appear-leave-active {
  transition: all ease-in-out 0.25s;
  transform: none;
  opacity: 1;
}

.modal-appear-enter-from,
.modal-appear-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.loading {
  display: block;
  height: 24px;
  margin: auto;
}

.error {
  color: rgb(150, 0, 48);
  display: block;
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

</style>
