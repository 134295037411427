<template>
  <div class="patient-profile-dropdown">
    <img :src="patientPicture" alt="Patient Picture" class="patient-picture">
    <h2 class="patient-name">{{ getPatientName() }}</h2>
    <div class="row">
      <label class="form-label">Date of Birth</label>
      <p class="form-control">{{ patient.dateOfBirth }}</p>
    </div>
    <div class="row">
      <label class="form-label">Phone Number</label>
      <p class="form-control">{{ getPatientPhone() }}</p>
    </div>
    <div class="row">
      <label class="form-label">Email Address</label>
      <p class="form-control">{{ patient.emailAddress }}</p>
    </div>
    <div class="row action-row">
      <a :href="getChartLink()" target="_blank" class="button primary">View Chart</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientProfileDropdown',
  props: {
    patient: Object,
    patientPicture: String
  },
  methods: {
    getFormattedDate(dateStr) {
      const date = new Date(dateStr);
      const now = new Date();
      const ms = now - date;

      const sec = Math.floor(ms / 1000);
      if (sec <= 60) {
        return "just now";
      }

      const min = Math.floor(sec / 60);
      if (min <= 1) {
        return `1 minute ago`;
      }
      if (min <= 60) {
        return `${min} minutes ago`;
      }

      const hr = Math.floor(min / 60);
      if (hr <= 1) {
        return "1 hour ago";
      }
      if (hr <= 24) {
        return `${hr} hours ago`;
      }

      const days = Math.floor(hr / 24);
      if (days <= 1) {
        return "1 day ago";
      }
      if (days <= 7) {
        return `${days} days ago`;
      }

      return date.toLocaleDateString();
    },
    getPatientPhone() {
      const phoneNumber = this.patient.mobilePhoneNumber || '';
      const digits = phoneNumber.replace(/[^0-9]/g, '');
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`
    },
    getPatientName() {
      const patient = this.patient;
      const name = [
        patient.firstName,
        patient.middleName,
        patient.lastName
      ].filter(part => part).join(' ');
      return name;
    },
    getChartLink() {
      const portalUrl = process.env.VUE_APP_PORTAL;
      const patientName = `${this.patient.firstName} ${this.patient.lastName}`
      return `${portalUrl}/patients?search=${encodeURIComponent(patientName)}`
    }
  }
}
</script>

<style scoped>
.patient-profile-dropdown {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  min-width: 350px;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 30px;
}

.patient-picture {
  width: 100px;
  aspect-ratio: 1;
  display: block;
  border-radius: 50%;
}

.patient-name {
  text-wrap: nowrap;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.action-row {
  border-top: 1px solid #EEE;
  padding-top: 20px;
}

.button {
  text-align: center;
  text-decoration: none;
}
</style>