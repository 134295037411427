<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Create Group Chat</h1>
      <p class="subtitle">What should we call this group chat? Pick something descriptive so you can recognize it on
        your list.</p>
    </div>
    <div class="modal-card-body">
      <div class="user-icons">
        <img class="user-picture" :src="getProfilePicture(user)" :title="user.name" :alt="user.name" v-for="user of users" :key="user.id">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Chat Name</label>
        <input type="text" class="form-control" v-model="name">
      </div>
    </div>
    <div class="modal-card-footer">
      <button class="button primary" @click.prevent="createChat">Create</button>
    </div>
  </div>
</template>

<script>
import { createGroupChat } from '@/api'

export default {
  name: "NameGroupChatModal",
  props: {
    users: Array
  },
  data() {
    return {
      name: ''
    }
  },
  methods: {
    getProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    async createChat() {
      const userIds = this.users.map(user => user.id);
      const chat = await createGroupChat(this.name, userIds);
      this.$emit('closeModal');
      setTimeout(() => {
        this.$router.push(`/groupchats/${chat.id}`);
      }, 100);
    }
  },
  emits: ['openModal', 'closeModal']
};
</script>

<style scoped>
.modal-card {
  max-width: 550px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.user-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 25px;
}

.user-picture {
  width: 30px;
  height: 30px;
  background: #EEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
</style>