export async function userCheckAuth() {
  await postJson('/core/user/check-auth');
}

export async function userInfo() {
  const resJson = await postJson('/core/user/info');
  if (!resJson.user) throw Error('Malformed response from backend');
  return resJson.user;
}

export async function listUsers() {
  const resJson = await postJson('/core/user/list-all');
  if (!resJson.users) throw Error('Malformed response from backend');
  return resJson.users;
}

export async function createGroupChat(name, users) {
  const resJson = await postJson('/messaging/groupchat/create', { name, users });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function listGroupChats(afterChatId) {
  const resJson = await postJson('/messaging/groupchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchGroupChatInfo(chatId) {
  const resJson = await postJson('/messaging/groupchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function logout() {
  await postJson('/core/user/logout');
}

export async function listPatients(pageNum, pageSize) {
  const resJson = await postJson('/core/patient/list', { pageNum, pageSize });
  return {
    patients: resJson.patients,
    currentPage: resJson.currentPage,
    totalPages: resJson.totalPages
  }
}

export async function createPatient(firstName, middleName, lastName, dateOfBirth, mobilePhoneNumber, emailAddress, emrIntegrationId) {
  const resJson = await postJson('/core/patient/create', {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    mobilePhoneNumber,
    emailAddress,
    emrIntegrationId
  });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function fetchPatient(patientId) {
  const resJson = await postJson('/core/patient/info', { patientId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function urlToPatientId(privateURL) {
  const resJson = await postJson('/messaging/patientchat/url-to-patient-id', { privateURL });
  if (!resJson.patientId) throw Error('Malformed response from backend');
  return resJson.patientId;
}

export async function patientLogin(patientId, dateOfBirth) {
  await postJson('/core/patient/login', { patientId, dateOfBirth });
}

export async function searchPatients(query, afterPatientId) {
  const resJson = await postJson('/core/patient/search', { query, afterPatientId });
  if (!resJson.patients) throw Error('Malformed response from backend');
  return resJson;
}

// Chats

export async function createPatientChat(patientId) {
  const resJson = await postJson('/messaging/patientchat/create', { patientId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function listLatestPatientChats(afterChatId) {
  const resJson = await postJson('/messaging/patientchat/list', { afterChatId });
  if (!resJson.chats) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchPatientChatInfo(chatId) {
  const resJson = await postJson('/messaging/patientchat/info-id', { chatId });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function fetchPatientChatInfoForPatient(patientId) {
  // Attempt to fetch info for patient
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchat/info-patient', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ patientId })
  });

  // If it's a 404, patient does not have chat
  if (res.status == 404) {
    return undefined;
  }

  // Otherwise attempt to return as always
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json.chat;
}

export async function fetchPatientChatInfoForPrivateUrl(privateURL) {
  // Attempt to fetch info for patient
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchat/info-url', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ privateURL })
  });

  // If it's a 401, patient is not authenticated right now
  if (res.status == 401) {
    return undefined;
  }

  // Otherwise attempt to return as always
  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json.chat;
}

export async function markChatResolved(chatId, isResolved) {
  const resJson = await postJson('/messaging/patientchat/mark-resolved', { chatId, isResolved });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

// Files

export async function sendPatientFileMessage(file) {
  const formBody = new FormData();
  formBody.set('file', file);

  const resJson = await postForm('/messagefile/send-patient-upload', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

// Templates
export async function fetchTemplates() {
  const resJson = await postJson('/messaging/template/list');
  if (!resJson.templates) throw Error('Malformed response from backend');
  return resJson.templates;
}

export async function createTemplate(name, text) {
  const resJson = await postJson('/messaging/template/create', { name, text });
  if (!resJson.template) throw Error('Malformed response from backend');
  return resJson.template;
}

export async function deleteTemplate(templateId) {
  await postJson('/messaging/template/remove', { templateId });
}

export async function editTemplate(templateId, name, text) {
  const resJson = await postJson('/messaging/template/edit', { templateId, name, text });
  if (!resJson.template) throw Error('Malformed response from backend');
  return resJson.template;
}

export async function getMessagingSettings() {
  const resJson = await postJson('/messaging/settings/get');
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function downloadPatientProfilePicture(patientId) {
  const res = await fetch(process.env.VUE_APP_API + '/core/patient/download-profile-picture', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ patientId })
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const blob = await res.blob();
  return blob;
}

export async function uploadPatientProfilePicture(patientId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('patientId', patientId);

  const resJson = await postForm('/core/patient/upload-profile-picture', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function downloadUserProfilePicture(userId) {
  const res = await fetch(process.env.VUE_APP_API + '/core/user/download-profile-picture', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId })
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const blob = await res.blob();
  return blob;
}

export async function useOneTimeCode(oneTimeCode) {
  await postJson('/core/user/use-otc', { oneTimeCode });
}

// PATIENT CHAT MESSAGE

export async function patientChatListMessages(chatId, afterMessageId) {
  const resJson = await postJson('/messaging/patientchatmessage/list', { chatId, afterMessageId });
  if (!resJson.messages) throw Error('Malformed response from backend');
  return resJson;
}

export async function patientChatSendUserText(chatId, text, isSecure) {
  const resJson = await postJson('/messaging/patientchatmessage/send-user-text', { chatId, text, isSecure });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendPatientText(chatId, text) {
  const resJson = await postJson('/messaging/patientchatmessage/send-patient-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendUserFile(chatId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('chatId', chatId);

  const resJson = await postForm('/messaging/patientchatmessage/send-user-file', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatSendPatientFile(chatId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('chatId', chatId);

  const resJson = await postForm('/messaging/patientchatmessage/send-patient-file', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function patientChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/patientchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function downloadPatientChatFile(fileId) {
  const res = await fetch(process.env.VUE_APP_API + '/messaging/patientchatmessage/download-file', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileId: fileId })
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const blob = await res.blob();
  return blob;
}

// GROUP CHAT

export async function groupChatAddMembers(chatId, users) {
  const resJson = await postJson('/messaging/groupchat/add-members', { chatId, users });
  if (!resJson.chat) throw Error('Malformed response from backend');
  return resJson.chat;
}

export async function groupChatLeaveChat(chatId) {
  await postJson('/messaging/groupchat/leave-chat', { chatId });
}

// GROUP CHAT MESSAGE

export async function groupChatListMessages(chatId, afterMessageId) {
  const resJson = await postJson('/messaging/groupchatmessage/list', { chatId, afterMessageId });
  if (!resJson.messages) throw Error('Malformed response from backend');
  return resJson;
}

export async function groupChatSendUserText(chatId, text) {
  const resJson = await postJson('/messaging/groupchatmessage/send-user-text', { chatId, text });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatSendUserFile(chatId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('chatId', chatId);

  const resJson = await postForm('/messaging/groupchatmessage/send-user-file', formBody);
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupChatMessageInfo(messageId) {
  const resJson = await postJson('/messaging/groupchatmessage/info', { messageId });
  if (!resJson.message) throw Error('Malformed response from backend');
  return resJson.message;
}

export async function groupPatientChatFile(fileId) {
  const res = await fetch(process.env.VUE_APP_API + '/messaging/groupchatmessage/download-file', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ fileId: fileId })
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const blob = await res.blob();
  return blob;
}

async function postForm(url, formData) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    body: formData
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

async function postJson(url, body) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}