export async function userCheckAuth() {
  await postJson('/core/user/check-auth');
}

export async function createAccount(name, practiceType, practiceEMR, masterEmail, masterPassword) {
  const resJson = await postJson('/core/account/create', { name, practiceType, practiceEMR, masterEmail, masterPassword });
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function fetchAccountInfo() {
  const resJson = await postJson('/core/account/info');
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function editAdminMessagingSettings(accountId, isEnabled, smsSenderNumber, optInMessage) {
  const resJson = await postJson('/messaging/settings/set-admin', { accountId, isEnabled, smsSenderNumber, optInMessage });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function getAdminMessagingSettings(accountId) {
  const resJson = await postJson('/messaging/settings/get-admin', { accountId });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function userTwoFactor(email, password) {
  await postJson('/core/user/two-factor', { email, password });
}


export async function logout() {
  await postJson('/core/user/logout');
}

export async function removeAccount(accountId) {
  await postJson('/core/account/remove', { accountId });
}

export async function createUser(invitationURL, password) {
  await postJson('/core/user/create', { invitationURL, password });
}

export async function removeUser(userId) {
  await postJson('/core/user/remove', { userId });
}

export async function editUser(userId, name, practiceRole) {
  const resJson = await postJson('/core/user/edit', { userId, name, practiceRole });
  if (!resJson.user) throw Error('Malformed response from backend');
  return resJson.user;
}

export async function userLogin(email, password, twoFactor) {
  await postJson('/core/user/login', { email, password, twoFactor });
}

export async function userInfo() {
  const resJson = await postJson('/core/user/info');
  if (!resJson.user) throw Error('Malformed response from backend');
  return resJson.user;
}

export async function fetchAllUsers() {
  const resJson = await postJson('/core/user/list-all');
  if (!resJson.users) throw Error('Malformed response from backend');
  return resJson.users;
}

export async function fetchPatient(patientId) {
  const resJson = await postJson('/core/patient/info', { patientId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function createPatient(firstName, middleName, lastName, dateOfBirth, mobilePhoneNumber, emailAddress, emrIntegrationId) {
  const resJson = await postJson('/core/patient/create', {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    mobilePhoneNumber,
    emailAddress,
    emrIntegrationId
  });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function archivePatient(patientId) {
  return await postJson('/core/patient/archive', { patientId });
}

export async function updatePatient(patientId, fields) {
  const resJson = await postJson('/core/patient/update', { patientId, ...fields });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function searchPatients(query, afterPatientId) {
  const resJson = await postJson('/core/patient/search', { query, afterPatientId });
  if (!resJson.patients) throw Error('Malformed response from backend');
  return resJson;
}

export async function listInvitations() {
  const resJson = await postJson('/core/userinvitation/list');
  if (!resJson.invitations) throw Error('Malformed response from backend');
  return resJson.invitations;
}

export async function inviteUser(name, email, practiceRole) {
  const resJson = await postJson('/core/userinvitation/create', { name, email, practiceRole });
  if (!resJson.invitation) throw Error('Malformed response from backend');
  return resJson.invitation;
}

export async function revokeInvite(invitationId) {
  await postJson('/core/userinvitation/revoke', { invitationId });
}

export async function inviteInfo(privateURL) {
  const resJson = await postJson('/core/userinvitation/info', { privateURL });
  if (!resJson.invitation) throw Error('Malformed response from backend');
  return resJson.invitation;
}

export async function getMessagingSettings() {
  const resJson = await postJson('/messaging/settings/get');
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function setMessagingSettings(settings) {
  const resJson = await postJson('/messaging/settings/set', { ...settings });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function emrToPatient(emrIntegrationId) {
  const resJson = await postJson('/core/patient/emr-to-patient', { emrIntegrationId });
  return resJson;
}

export async function uploadPatientProfilePicture(patientId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('patientId', patientId);

  const resJson = await postForm('/core/patient/upload-profile-picture', formBody);
  if (!resJson.key) throw Error('Malformed response from backend');
  return resJson.key;
}

export async function uploadUserProfilePicture(userId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('userId', userId);

  const resJson = await postForm('/core/user/upload-profile-picture', formBody);
  if (!resJson.key) throw Error('Malformed response from backend');
  return resJson.key;
}

export async function listAccounts() {
  const resJson = await postJson('/core/account/list');
  if (!resJson.accounts) throw Error('Malformed response from backend');
  return resJson.accounts;
}

// ADMIN

export async function adminLogin(accountId) {
  await postJson('/core/account/admin-login', { accountId });
}

async function postForm(url, formData) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    body: formData
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}

async function postJson(url, body) {
  const res = await fetch(process.env.VUE_APP_API + url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });

  // Handle 401 - Permission Denied
  if (res.status == 401) {
    throw Error('Permission denied');
  }

  const json = await res.json();
  if (res.status != 200) {
    throw Error(json.message || res.statusText);
  }
  return json;
}