<template>
  <nav>
    <a :href="portalUrl" id="logo">
      <img src="@/assets/logo.png" alt="DashQuill Logo" />
    </a>
    <div class="left">
      <router-link class="nav-link" :class="{ 'is-active': $route.path.startsWith('/patients') }" to="/patients">
        <span>Patient Chats</span>
      </router-link>
      <router-link class="nav-link" :class="{ 'is-active': $route.path.startsWith('/groupchats') }" to="/groupchats">
        <span>Group Chats</span>
      </router-link>
    </div>
    <div class="right">
      <div class="user-info" ref="userInfo" v-if="user" @click.prevent="openPopup">
        <img class="user-profile-picture" :src="getProfilePicture()" alt="Profile Picture">
        <span class="user-name">{{ user.name }}</span>
        <transition name="modal-appear" mode="out-in">
          <div class="user-actions-popup" v-if="popupOpen">
            <div class="action-link" @click="openAccountSettings">
              <span>Account Settings</span>
            </div>
            <div class="action-link" @click="openUserSettings">
              <span>User Settings</span>
            </div>
            <div class="action-link" @click="logout" style="border-top: 1px solid var(--color-border-med);">
              <span>Logout</span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </nav>
</template>

<script>
import { userInfo, logout } from '@/api'

export default {
  name: 'NavBar',
  data() {
    return {
      portalUrl: process.env.VUE_APP_PORTAL,
      user: null,
      popupOpen: false
    }
  },
  mounted() {
    this.attemptFetchUser();

    window.addEventListener('click', this.onClickPage);
  },
  unmounted() {
    window.removeEventListener('click', this.onClickPage);
  },
  methods: {
    getProfilePicture() {
      if (!this.user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${this.user.profilePictureKey}`;
    },
    openPopup() {
      this.popupOpen = true;
    },
    openAccountSettings() {
      window.location.href = `${this.portalUrl}/settings`;
    },
    openUserSettings() {
      window.location.href = `${this.portalUrl}/settings/users`;
    },
    onClickPage(event) {
      const target = event.target;

      const userInfoRef = this.$refs.userInfo;
      if (!userInfoRef || !userInfoRef.contains(target)) {
        this.popupOpen = false;
      }
    },
    async logout() {
      try {
        await logout();
        window.location.href = `${this.portalUrl}/login`;
      } catch (err) {
        console.error('Failed to logout', err);
      }
    },
    async attemptFetchUser() {
      try {
        this.user = await userInfo();
      } catch (err) {
        console.error('Failed to fetch user info', err);
      }
    }
  }
}
</script>

<style scoped>
nav {
  position: fixed;
  background: #fff;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  height: 70px;
  box-shadow: 0px 0px 8px #00000022;
  z-index: 1000;
}

.left {
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
}

.right {
  display: flex;
  align-items: center;
  height: 100%;
}

#logo {
  display: block;
  width: 110px;
}

#logo img {
  width: 100%;
}

.nav-link {
  margin-left: 25px;
  color: #000;
  text-decoration: none;
}

.nav-link.is-active {
  text-shadow: 0px 0px 1px #000;
}

.nav-link img {
  display: block;
  height: 24px;
}

.socket-status {
  color: #999;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}


.user-info {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.user-name {
  color: var(--color-txt-med);
}

.user-profile-picture {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
}

.user-actions-popup {
  cursor: default;
  position: absolute;
  z-index: 1001;
  top: calc(100% + 10px);
  right: 0;
  background: var(--color-fill-light);
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.action-link {
  display: block;
  text-wrap: nowrap;
  padding: 15px;
  cursor: pointer;
  color: var(--color-txt-dark);
}

.action-link:hover {
  background: var(--color-fill-med);
}

@media screen and (max-width: 1000px) {
  .user-name {
    display: none;
  }
}
</style>