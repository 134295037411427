<template>
  <div id="full">
    <NavBar />
    <OfficeGroupChatsSidebar @openModal="openModal" />
    <transition name="modal-appear" mode="out-in">
      <OfficeGroupChatPanel ref="panel" v-if="chatInfo && userInfo" :chatInfo="chatInfo" :userInfo="userInfo" :settings="settings"
        @openModal="openModal" @closeModal="closeModal" />
    </transition>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <CreateGroupChatModal v-if="modal.id == 'create-group-chat'" :userInfo="userInfo" @openModal="openModal" />
            <NameGroupChatModal v-else-if="modal.id == 'name-group-chat'" :users="modal.data.users" @closeModal="closeModal" />
            <UploadFilesModal v-else-if="modal.id == 'upload-files'" @closeModal="closeModal" @onFileUploaded="handleFileUploaded" />
            <AddGroupChatMemberModal v-else-if="modal.id == 'add-member'" :chatInfo="chatInfo" @closeModal="closeModal"/>
            <LeaveGroupChatModal v-else-if="modal.id == 'leave-chat'" :chatInfo="chatInfo" @closeModal="closeModal"/>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as socket from '@/socket.js'
import {fetchGroupChatInfo, userCheckAuth, userInfo} from '@/api'
import NavBar from '../components/NavBar.vue'
import OfficeGroupChatsSidebar from '@/components/officegroupchat/OfficeGroupChatsSidebar.vue';
import OfficeGroupChatPanel from '../components/officegroupchat/OfficeGroupChatPanel.vue';
import CreateGroupChatModal from '../modals/officegroupchat/CreateGroupChatModal.vue';
import NameGroupChatModal from '@/modals/officegroupchat/NameGroupChatModal.vue';
import UploadFilesModal from '../modals/UploadFilesModal.vue';
import AddGroupChatMemberModal from '../modals/officegroupchat/AddGroupChatMemberModal.vue';
import LeaveGroupChatModal from '../modals/officegroupchat/LeaveGroupChatModal.vue';

export default {
  name: 'OfficeGroupChatView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      chatInfo: null,
      userInfo: null,
      settings: {}
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        const chatId = to.params.id;
        this.chatInfo = null;
        if (chatId) {
          this.loadChatInfo(chatId);
        }
      },
      immediate: true
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          const portalUrl = process.env.VUE_APP_PORTAL;
          const currentUrl = window.location.href;
          window.location.replace(portalUrl + '/login?ref=' + currentUrl);
        }
      });
  },
  mounted() {
    this.listenForUpdates();
    this.attemptFetchUserInfo();
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleFileUploaded(file, errorCallback) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.handleFileUploaded(file, errorCallback);
    },
    async loadChatInfo(chatId) {
      try {
        const chatInfo = await fetchGroupChatInfo(chatId);
        this.chatInfo = chatInfo;
      } catch (err) {
        console.error('Failed to fetch chat info', err.message);
      }
    },
    async attemptFetchUserInfo() {
      try {
        this.userInfo = await userInfo();
      } catch (err) {
        console.error('Failed to fetch user info', err);
      }
    },
    async listenForUpdates() {
      if (!socket.isConnectedToSocket()) {
        await socket.connectToSocket();
        console.info('Connected to socket');
      }

      await socket.joinAccountRoom();
      console.info('Joined account room');
    },
  },
  components: { NavBar, OfficeGroupChatsSidebar, CreateGroupChatModal, NameGroupChatModal, OfficeGroupChatPanel, UploadFilesModal, AddGroupChatMemberModal, LeaveGroupChatModal }
}
</script>

<style scoped>
#full {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 80px;
}

@media screen and (max-width: 1000px) {
  #full {
    padding: 0px;
    padding-top: 70px;
  }
}
</style>