import { createRouter, createWebHistory } from 'vue-router'
import OfficePatientChatView from '@/views/OfficePatientChatView.vue'
import OfficePatientChatViewEmbed from '@/views/OfficePatientChatViewEmbed.vue'
import PatientChatView from '@/views/PatientChatView.vue'
import OfficeGroupChatView from '@/views/OfficeGroupChatView.vue'

const routes = [
  {
    name: 'Office Patient Chat',
    path: '/patients/:id?',
    component: OfficePatientChatView
  },
  {
    name: 'Office Group Chat',
    path: '/groupchats/:id?',
    component: OfficeGroupChatView
  },
  {
    name: 'Office Patient Chat Embed',
    path: '/embed/:emrId',
    component: OfficePatientChatViewEmbed
  },
  {
    name: 'Patient Chat',
    path: '/chat/:url',
    component: PatientChatView
  }
]

const router = createRouter({
  routes: routes,
  history: createWebHistory(),
  linkActiveClass: 'is-active'
})

export default router;