<template>
  <div class="patient-chat-panel">
    <div class="panel-body" ref="body" @scroll="onScroll">
      <div id="messages" ref="messages" :style="{ minHeight: doneLoadingMessages ? '' : 'calc(100% + 1px)' }">
        <div class="loading-status">
          <span v-if="doneLoadingMessages" class="loading-done">Start of Conversation</span>
          <img src="/loading.svg" class="loading-icon" v-if="loading">
        </div>
        <ChatMessageRow v-for="(message, idx) of messages" :key="message.id" :message="message" :chatWidth="chatWidth"
          :isLastOfKind="isMessageLastOfKind(idx)" :isFirstOfKind="isMessageFirstOfKind(idx)"
          :isFirstOfDay="isMessageFirstOfDay(idx)" :fromSelfSide="isMessageFromSelfSide(message)"
          :hideSelfPicture="true" :profilePicture="getProfilePictureForMessage(message)" :hasActions="false" />
      </div>
    </div>
    <div class="panel-footer">
      <button class="button small" @click="$emit('openModal', 'upload-files')">
        <img src="/icons/upload.png" alt="Upload">
      </button>
      <textarea class="form-control" ref="text" rows="1" v-model="text"
        placeholder="Send message..." @input="resizeTextArea"></textarea>
      <button class="button primary small" @click="sendTextMessage">Send</button>
    </div>
  </div>
</template>

<script>
import { patientChatListMessages, patientChatMessageInfo, patientChatSendPatientText, patientChatSendPatientFile } from '@/api'
import * as socket from '@/socket.js'
import ChatMessageRow from '../ChatMessageRow.vue';

const FAKE_LATENCY = 300;

export default {
  name: 'PatientChatPanel',
  props: {
    chatInfo: Object
  },
  data() {
    return {
      loading: false,
      messages: [],
      doneLoadingMessages: false,
      text: "",
      savedScrollBottom: 0,
      userPictures: new Map()
    }
  },
  watch: {
    chatInfo: {
      handler: function (to, from) {
        if (from == null && to != null) {
          this.loadLatestMessages();
        }
      },
      immediate: true
    }
  },
  mounted() {
    socket.addListener('chat-update-event', this.handleUpdateEvent);
    window.addEventListener('resize', this.computeChatWidth);
    this.computeChatWidth();
  },
  unmounted() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
    window.removeEventListener('resize', this.computeChatWidth);
  },
  methods: {
    async loadLatestMessages() {
      try {
        this.loading = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await patientChatListMessages(chatId);
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.loading = false;

        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async loadNextMessages() {
      if (this.loading) {
        return; // Already loaded
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const chatId = this.chatInfo.id;
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await patientChatListMessages(chatId, oldestLoadedMessage.id);

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest chats', err);
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          const body = this.$refs.body;
          if (!body) return;
          const scrollHeight = body.scrollHeight;
          const scrollBottom = scrollHeight - this.savedScrollBottom;
          body.scrollTop = scrollBottom;
        })
      }
    },
    async sendTextMessage() {
      try {
        this.loading = true;
        const chatId = this.chatInfo.id;
        const text = this.text;
        const message = await patientChatSendPatientText(chatId, text);
        if (!this.isMessageInChat(message.id)) {
          this.messages.push(message);
        }
      } catch (err) {
        console.error('Failed to send message', err);
      } finally {
        this.text = "";
        this.loading = false;

        this.$nextTick(() => {
          this.resizeTextArea();
          this.scrollToBottom();
        })
      }
    },
    async handleFileUploaded(file, errorCallback) {
      try {
        const chatId = this.chatInfo.id;
        const message = await patientChatSendPatientFile(chatId, file);
        if (!this.isMessageInChat(message.id)) {
          this.messages.push(message);
        }
        
        this.$emit('closeModal');
      } catch (err) {
        console.error('Failed to send message', err);
        errorCallback(err);
      } finally {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    getUserProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    getProfilePictureForMessage(message) {
      if (message.sender.type == 'Patient') {
        return '/icons/patient.png'
      }

      if (message.sender.type == 'User') {
        return this.getUserProfilePicture(message.sender);
      }
      
      return '/icons/patient.png'
    },
    getChatName() {
      const patient = this.chatInfo.patient;
      const name = [
        patient.firstName,
        patient.middleName,
        patient.lastName
      ].filter(part => part).join(' ');

      return name;
    },
    isMessageFirstOfDay(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];

      const datePrev = new Date(prev.dateCreated);
      const dateCurr = new Date(curr.dateCreated);
      return datePrev.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageLastOfDay(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];

      const dateCurr = new Date(curr.dateCreated);
      const dateNext = new Date(next.dateCreated);
      return dateNext.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageFirstOfKind(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      if (this.isMessageFirstOfDay(index)) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];
      return !this.areMessagesGrouped(prev, curr);
    },
    isMessageLastOfKind(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      if (this.isMessageLastOfDay(index)) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];
      return !this.areMessagesGrouped(curr, next);
    },
    isMessageInChat(messageId) {
      return this.messages.find(msg => msg.id == messageId) != null;
    },
    isMessageFromSelfSide(message) {
      if (message.sender.type == 'Patient') {
        return true;
      }

      return false;
    },
    areMessagesGrouped(message1, message2) {
      const person1 = message1.sender;
      const person2 = message2.sender;

      // Split secure messages from non-secure
      if (message1.isSecure != message2.isSecure) {
        return false;
      }

      // Different types, cannot be same
      if (person1.type != person2.type) {
        return false;
      }

      // Both are user, do their ids match?
      if (person1.type == 'User') {
        return person1.id == person2.id;
      }

      // Both are patient (must be same)
      return true;
    },
    computeChatWidth() {
      const messagesRef = this.$refs.messages;
      this.chatWidth = messagesRef.offsetWidth;
    },
    scrollToBottom() {
      const body = this.$refs.body;
      body.scrollTop = body.scrollHeight;
    },
    onScroll(event) {
      if (this.doneLoadingMessages) {
        return; // Previous fetch returned 0 chats
      }

      const body = event.target;
      const scrollHeight = body.scrollHeight;
      const scrollTop = body.scrollTop;
      const scrollBottom = scrollHeight - scrollTop;

      // Save current scroll position
      this.savedScrollBottom = scrollBottom;

      // Are we at bottom? Try to fetch chats
      if (scrollTop <= 0) {
        this.loadNextMessages();
      }
    },
    isAtBottom() {
      const body = this.$refs.body;
      if (!body) return false;
      const scrollHeight = body.scrollHeight - body.offsetHeight;
      const scrollBottom = scrollHeight - body.scrollTop;
      return scrollBottom < 20;
    },
    async handleUpdateEvent(data) {
      const { messageId } = data;

      try {
        // Fetch message that was created
        const message = await patientChatMessageInfo(messageId);

        // Remove message from messa (if it exists), push new version
        this.messages = this.messages.filter(msg => msg.id != message.id);
        this.messages.push(message);

        // Sort chats by date last updated
        this.messages.sort((a, b) => {
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        })

        // If we were at bottom, scroll down
        if (this.isAtBottom()) {
          this.$nextTick(() => {
            this.scrollToBottom();
          })
        }

      } catch (err) {
        console.error('Failed to fetch message info for update', err);
      }
    },
    resizeTextArea() {
      const target = this.$refs.text;
      target.style.height = '0px';
      target.style.height = `${target.scrollHeight}px`
    },
  },
  components: { ChatMessageRow },
  emits: ['openModal']
}
</script>

<style scoped>
.patient-chat-panel {
  flex: 1;
  height: 100%;
  max-width: 1000px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: 1px solid #EEE;
  border-right: 1px solid #EEE;
}

.panel-header {
  padding: 15px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  align-items: center;
}

.chat-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.chat-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #EEE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-icon img {
  display: block;
  width: 20px;
  height: 20px;
}

.chat-name {
  font-weight: bold;
}

.chat-label {
  color: #999;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
}

.panel-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: none;
}

#messages {
  width: 100%;
  padding: 15px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.panel-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 15px;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  gap: 10px;
}

.panel-footer .button {
  flex-shrink: 0;
  height: 43px;
}

.panel-footer textarea {
  resize: none;
  overflow: hidden;
}

.loading-icon {
  display: block;
  height: 100%;
}

.button img {
  height: 21px;
  display: block;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.loading-status span {
  text-align: center;
  padding: 10px;
  background: #e0efff;
  color: #007eff;
  border-radius: 10px;
}
</style>