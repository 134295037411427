<template>
  <div id="full">
    <NavBar />
    <OfficePatientChatSidebar @openModal="openModal" :chatInfo="chatInfo" />
    <transition name="modal-appear" mode="out-in">
      <OfficePatientChatPanel ref="panel" v-if="chatInfo" :chatInfo="chatInfo" :settings="settings"
        @openModal="openModal" @closeModal="closeModal" @onChatCreated="handleChatCreated" />
    </transition>
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <StartPatientChatModal v-if="modal.id == 'create-chat'" @openModal="openModal" @closeModal="closeModal" />
            <CreatePatientModal v-else-if="modal.id == 'create-patient'" @closeModal="closeModal"
              :values="modal.data" />
            <UploadFilesModal v-else-if="modal.id == 'upload-files'" @closeModal="closeModal" :chatInfo="chatInfo"
              @onFileUploaded="handleFileUploaded" />
            <SelectTemplateModal v-else-if="modal.id == 'select-template'" :chatInfo="chatInfo" @closeModal="closeModal"
              @selectTemplate="handleSelectTemplate" @openModal="openModal" />
            <CreateTemplateModal v-else-if="modal.id == 'create-template'" @closeModal="closeModal"
              @selectTemplate="handleSelectTemplate" />
            <EditTemplateModal v-else-if="modal.id == 'edit-template'" @closeModal="closeModal"
              :template="modal.data.template" />
            <PopulateTemplateModal v-else-if="modal.id == 'populate-template'" :text="modal.data.text"
              @selectTemplate="handleSelectTemplate" @closeModal="closeModal" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { userCheckAuth, fetchPatientChatInfoForPatient, fetchPatient, getMessagingSettings } from '@/api'

import NavBar from '../components/NavBar.vue'
import OfficePatientChatSidebar from '../components/officepatientchat/OfficePatientChatsSidebar.vue'
import OfficePatientChatPanel from '../components/officepatientchat/OfficePatientChatPanel.vue'
import StartPatientChatModal from '../modals/StartPatientChatModal.vue'
import CreatePatientModal from '@/modals/CreatePatientModal.vue'
import UploadFilesModal from '../modals/UploadFilesModal.vue'
import SelectTemplateModal from '@/modals/SelectTemplateModal.vue'
import CreateTemplateModal from '@/modals/CreateTemplateModal.vue'
import PopulateTemplateModal from '@/modals/PopulateTemplateModal.vue'
import EditTemplateModal from '@/modals/EditTemplateModal.vue'
import * as socket from '../socket.js'

export default {
  name: 'OfficePatientChatsView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      chatInfo: null,
      settings: {}
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        const patientId = to.params.id;
        this.chatInfo = null;
        if (patientId) {
          this.loadChatInfo(patientId);
        }
      },
      immediate: true
    }
  },
  created() {
    userCheckAuth()
      .then(() => {
        console.info('Login checker - OK');
      })
      .catch(err => {
        if (err.message == 'Permission denied') {
          const portalUrl = process.env.VUE_APP_PORTAL;
          const currentUrl = window.location.href;
          window.location.replace(portalUrl + '/login?ref=' + currentUrl);
        }
      });
  },
  mounted() {
    this.listenForUpdates();
    this.attemptLoadSettings();
    socket.addListener('chat-update-event', this.handleUpdateEvent);
  },
  beforeUnmount() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleChatCreated(chat) {
      this.chatInfo = chat;
    },
    handleSelectTemplate(text) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.setText(text);
      this.closeModal();
    },
    handleFileUploaded(file, errorCallback) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.handleFileUploaded(file, errorCallback);
    },
    async loadChatInfo(patientId) {
      try {
        const chatInfo = await fetchPatientChatInfoForPatient(patientId);
        if (chatInfo) {
          // Got a chat, lets show it
          this.chatInfo = chatInfo;
        } else {
          // Got a 404, set up a dummy patient chat
          const patient = await fetchPatient(patientId);
          this.chatInfo = { patient }
        }

      } catch (err) {
        console.error('Failed to fetch chat info', err.message);
      }
    },
    async handleUpdateEvent(data) {
      const { chatId } = data;
      console.log('Updating chat', data);

      if (!this.chatInfo || this.chatInfo.id != chatId) {
        console.info('Ignoring....');
        return; // Not current chat, ignore
      }

      try {
        // Refetch chat (in case there's some update to it)
        const chat = await fetchPatientChatInfoForPatient(this.chatInfo.patient.id);
        this.chatInfo = chat;
        console.info('Updated chat: ', chat);

      } catch (err) {
        console.error('Failed to fetch chat info for updated chat', err);
      }
    },
    async listenForUpdates() {
      if (!socket.isConnectedToSocket()) {
        await socket.connectToSocket();
        console.info('Connected to socket');
      }

      await socket.joinAccountRoom();
      console.info('Joined account room');
    },
    async attemptLoadSettings() {
      try {
        this.settings = await getMessagingSettings();
      } catch (err) {
        console.error(err);
      }
    }
  },
  components: { NavBar, OfficePatientChatSidebar, OfficePatientChatPanel, StartPatientChatModal, CreatePatientModal, CreateTemplateModal, UploadFilesModal, SelectTemplateModal, PopulateTemplateModal, EditTemplateModal }
}
</script>

<style scoped>
#full {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 80px;
}

@media screen and (max-width: 1000px) {
  #full {
    padding: 0px;
    padding-top: 70px;
  }
}
</style>