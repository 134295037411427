<template>
  <div class="panel">
    <div class="panel-header">
      <div class="chat-info" ref="chatInfo">
        <div class="chat-icon-holder">
          <img :src="getPatientProfilePicture()" alt="Patient Icon" class="chat-icon"
            @click.prevent="setShowingProfileDropdown(true)">
          <transition name="modal-appear" mode="out-in">
            <PatientProfileDropdown v-if="showProfileDropdown" :patient="chatInfo.patient"
              :patientPicture="getPatientProfilePicture()" />
          </transition>
        </div>
        <span class="chat-name">{{ getChatName() }}</span>
        <span class="chat-label">{{ getChatPhone() }}</span>
      </div>
      <button class="button small mark-resolved" @click="attemptMarkChatResolved(true)"
        v-if="chatInfo && chatInfo.id && !chatInfo.isResolved">
        <div class="unresolved-icon"></div>
        Resolve Chat
      </button>
    </div>
    <div class="panel-body" ref="body" @scroll="onScroll">
      <div id="messages" ref="messages" :style="{ minHeight: doneLoadingMessages ? '' : 'calc(100% + 1px)' }">
        <div class="loading-status">
          <img src="/loading.svg" class="loading-icon" v-if="loading">
          <span v-else-if="doneLoadingMessages">{{ getLoadingStatus() }}</span>
        </div>
        <ChatMessageRow v-for="(message, idx) of messages" :key="message.id" :message="message" :chatWidth="chatWidth"
          :isLastOfKind="isMessageLastOfKind(idx)" :isFirstOfKind="isMessageFirstOfKind(idx)"
          :isFirstOfDay="isMessageFirstOfDay(idx)" :fromSelfSide="isMessageFromSelfSide(message)"
          :hideSelfPicture="isInMobile()" :profilePicture="getProfilePictureForMessage(message)" :hasActions="true" />
      </div>
    </div>
    <div class="panel-footer">
      <button class="button small" @click="$emit('openModal', 'upload-files')">
        <img src="/icons/upload.png" alt="Upload">
      </button>
      <button class="button small" @click="$emit('openModal', 'select-template')">
        <img src="/icons/template.png" alt="Template">
      </button>
      <textarea class="form-control" rows="1" ref="text" v-model="text" @keydown.enter="handleEnterPress"
        placeholder="Send message..." @input="resizeTextArea"></textarea>
      <button class="button primary small" v-if="settings.defaultChatMethod == 'Secure'"
        @click.prevent.stop="sendTextMessage(true)">Send Secure Message</button>
      <button class="button primary small" v-else-if="settings.defaultChatMethod == 'Insecure'"
        @click.prevent.stop="sendTextMessage(false)">Send SMS Message</button>
      <button class="button primary small" id="send" @click.prevent.stop="toggleSendOptions" v-else>
        <img src="/icons/up-arrow.png" alt="Up Arrow">
        <transition name="modal-pop" mode="out-in">
          <div class="send-popup" v-if="showSendOptions">
            <span @click.prevent.stop="sendTextMessage(true)"><img src="/icons/lock.png" alt="Lock Icon"
                class="lock-icon"> Send Secure Message</span>
            <span @click.prevent.stop="sendTextMessage(false)">Send SMS Message</span>
          </div>
        </transition>
      </button>
    </div>
  </div>
</template>

<script>
import * as socket from '@/socket.js'
import { createPatientChat, markChatResolved, patientChatListMessages, patientChatMessageInfo, patientChatSendUserText, patientChatSendUserFile } from '@/api'
import ChatMessageRow from '../ChatMessageRow.vue'
import PatientProfileDropdown from './PatientProfileDropdown.vue'

const FAKE_LATENCY = 300;

export default {
  name: 'OfficePatientChatPanel',
  props: {
    chatInfo: Object,
    settings: Object
  },
  data() {
    return {
      loading: false,
      messages: [],
      doneLoadingMessages: false,
      text: "",
      savedScrollBottom: 0,
      showSendOptions: false,
      showProfileDropdown: false,
      chatWidth: 0
    }
  },
  mounted() {
    socket.addListener('chat-update-event', this.handleUpdateEvent);
    window.addEventListener('click', this.onClickPage);
    window.addEventListener('resize', this.computeChatWidth);
    this.computeChatWidth();
  },
  beforeUnmount() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
    window.removeEventListener('click', this.onClickPage);
    window.removeEventListener('resize', this.computeChatWidth);
  },
  watch: {
    chatInfo: {
      handler: function (to, from) {
        if (from == null && to != null) {
          this.loadLatestMessages();
        }
      },
      immediate: true
    }
  },
  methods: {
    async loadLatestMessages() {
      if (!this.chatInfo.id) {
        console.warn('Chat does not exist yet, skipping loading messages');
        this.doneLoadingMessages = true;
        return;
      }

      try {
        this.loading = true;
        const chatId = this.chatInfo.id;
        const { messages, isLast } = await patientChatListMessages(chatId);
        this.messages = messages.reverse();
        this.doneLoadingMessages = isLast;
      } catch (err) {
        console.error('Failed to load latest messages', err);
      } finally {
        this.loading = false;

        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async loadNextMessages() {
      if (this.loading) {
        return; // Already loaded
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of messages
        const chatId = this.chatInfo.id;
        const oldestLoadedMessage = this.messages[0];
        const { messages, isLast } = await patientChatListMessages(chatId, oldestLoadedMessage.id);

        // Push new chats to list
        this.doneLoadingMessages = isLast;
        for (let message of messages) {
          this.messages.unshift(message);
        }

      } catch (err) {
        console.error('Failed to load latest chats', err);
      } finally {
        this.loading = false;
        this.$nextTick(() => {
          const body = this.$refs.body;
          if (!body) return;
          const scrollHeight = body.scrollHeight;
          const scrollBottom = scrollHeight - this.savedScrollBottom;
          body.scrollTop = scrollBottom;
        })
      }
    },
    async sendTextMessage(isSecure) {
      this.showSendOptions = false;

      const text = this.text;
      if (text.length < 1) return;
      this.text = "";
      this.$nextTick(() => {
        this.resizeTextArea();
      })

      try {
        let chatId = this.chatInfo.id;
        if (!chatId) {
          // Chat does not exist, create it and use it for this message
          const patientId = this.chatInfo.patient.id;
          const chat = await createPatientChat(patientId);
          chatId = chat.id;
          this.$emit('onChatCreated', chat);
        }

        const message = await patientChatSendUserText(chatId, text, isSecure);
        if (!this.isMessageInChat(message.id)) {
          this.messages.push(message);
        }
      } catch (err) {
        console.error('Failed to send message', err);
      } finally {
        this.showSendOptions = false;
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async handleFileUploaded(file, errorCallback) {
      try {
        let chatId = this.chatInfo.id;
        if (!chatId) {
          // Chat does not exist, create it and use it for this message
          const patientId = this.chatInfo.patient.id;
          const chat = await createPatientChat(patientId);
          chatId = chat.id;
          this.$emit('onChatCreated', chat);
        }

        const message = await patientChatSendUserFile(chatId, file);
        if (!this.isMessageInChat(message.id)) {
          this.messages.push(message);
        }

        this.$emit('closeModal');
      } catch (err) {
        console.error('Failed to send message', err);
        errorCallback(err);
      } finally {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async handleUpdateEvent(data) {
      const { chatId, messageId } = data;
      if (!this.chatInfo || this.chatInfo.id != chatId) {
        return; // Different chat, we don't care...
      }

      if (!messageId || this.isMessageInChat(messageId)) {
        return; // Not a message, or we know about it already
      }

      try {
        // Fetch message
        const message = await patientChatMessageInfo(messageId);

        // Remove message from messa (if it exists), push new version
        this.messages = this.messages.filter(msg => msg.id != message.id);
        this.messages.push(message);

        // Sort chats by date last updated
        this.messages.sort((a, b) => {
          return new Date(a.dateCreated) - new Date(b.dateCreated);
        })
      } catch (err) {
        console.error('Failed to fetch message info for update', err);
      }

      // If we were at bottom, scroll even more
      if (this.isAtBottom()) {
        this.$nextTick(() => {
          this.scrollToBottom();
        })
      }
    },
    async attemptMarkChatResolved(isResolved) {
      try {
        const chatId = this.chatInfo.id;
        await markChatResolved(chatId, isResolved);
      } catch (err) {
        console.error(err);
        alert('Failed to mark chat as closed');
      }
    },
    getPatientProfilePicture() {
      if (!this.chatInfo) {
        return '/icons/patient.png'
      }

      const patient = this.chatInfo.patient;
      if (!patient.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${patient.profilePictureKey}`;
    },
    getUserProfilePicture(user) {
      if (!user.profilePictureKey) {
        return '/icons/patient.png'
      }

      const publicImages = process.env.VUE_APP_PUBLIC_FILES;
      return `${publicImages}/${user.profilePictureKey}`;
    },
    getProfilePictureForMessage(message) {
      if (message.sender.type == 'Patient') {
        return this.getPatientProfilePicture()
      }

      if (message.sender.type == 'User') {
        return this.getUserProfilePicture(message.sender);
      }

      return '/icons/patient.png'
    },
    setShowingProfileDropdown(val) {
      if (!this.chatInfo) return;
      this.showProfileDropdown = val;
    },
    openSendOptions() {
      this.showSendOptions = true;
    },
    toggleSendOptions() {
      this.showSendOptions = !this.showSendOptions;
    },
    getChatName() {
      if (!this.chatInfo) {
        return 'New Chat'
      }

      const patient = this.chatInfo.patient;
      const name = [
        patient.firstName,
        patient.middleName,
        patient.lastName
      ].filter(part => part).join(' ');

      return name;
    },
    getChatPhone() {
      if (!this.chatInfo) return '';

      const patient = this.chatInfo.patient;
      const phoneNumber = patient.mobilePhoneNumber || '';
      const digits = phoneNumber.replace(/[^0-9]/g, '');
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`
    },
    isMessageFirstOfDay(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];

      const datePrev = new Date(prev.dateCreated);
      const dateCurr = new Date(curr.dateCreated);
      return datePrev.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageLastOfDay(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];

      const dateCurr = new Date(curr.dateCreated);
      const dateNext = new Date(next.dateCreated);
      return dateNext.toLocaleDateString() != dateCurr.toLocaleDateString();
    },
    isMessageFirstOfKind(index) {
      // Last message
      if (index == 0) {
        return true;
      }

      if (this.isMessageFirstOfDay(index)) {
        return true;
      }

      const prev = this.messages[index - 1];
      const curr = this.messages[index];
      return !this.areMessagesGrouped(prev, curr);
    },
    isMessageLastOfKind(index) {
      // Last message
      if (index == this.messages.length - 1) {
        return true;
      }

      if (this.isMessageLastOfDay(index)) {
        return true;
      }

      const curr = this.messages[index];
      const next = this.messages[index + 1];
      return !this.areMessagesGrouped(curr, next);
    },
    isMessageInChat(messageId) {
      return this.messages.find(msg => msg.id == messageId) != null;
    },
    isMessageFromSelfSide(message) {
      if (message.sender.type == 'Patient') {
        return false;
      }

      return true;
    },
    areMessagesGrouped(message1, message2) {
      const person1 = message1.sender;
      const person2 = message2.sender;

      // Split secure messages from non-secure
      if (message1.isSecure != message2.isSecure) {
        return false;
      }

      // Different types, cannot be same
      if (person1.type != person2.type) {
        return false;
      }

      // Both are user, do their ids match?
      if (person1.type == 'User') {
        return person1.id == person2.id;
      }

      // Both are patient (must be same)
      return true;
    },
    computeChatWidth() {
      const messagesRef = this.$refs.messages;
      this.chatWidth = messagesRef.offsetWidth;
    },
    scrollToBottom() {
      const body = this.$refs.body;
      if (!body) return;
      body.scrollTop = body.scrollHeight;
    },
    onScroll(event) {
      if (this.isAtBottom());

      if (this.doneLoadingMessages) {
        return; // Previous fetch returned 0 chats
      }

      const body = event.target;
      const scrollHeight = body.scrollHeight;
      const scrollTop = body.scrollTop;
      const scrollBottom = scrollHeight - scrollTop;

      // Save current scroll position
      this.savedScrollBottom = scrollBottom;

      // Are we at bottom? Try to fetch chats
      if (scrollTop <= 0) {
        this.loadNextMessages();
      }
    },
    isAtBottom() {
      const body = this.$refs.body;
      if (!body) return false;
      const scrollHeight = body.scrollHeight - body.offsetHeight;
      const scrollBottom = scrollHeight - body.scrollTop;
      return scrollBottom < 20;
    },
    resizeTextArea() {
      const target = this.$refs.text;
      target.style.height = '0px';
      target.style.height = `${target.scrollHeight}px`
    },
    getLoadingStatus() {
      if (!this.chatInfo) return 'Opt in request not sent yet'

      const patient = this.chatInfo.patient;
      switch (patient.smsOptInStatus) {
        case 'Yes': return 'Patient opted into SMS messaging';
        case 'Pending': return 'Waiting for patient to opt into messages';
        default: return 'Opt in request not sent yet'
      }
    },
    setText(text) {
      this.text = text;
      this.$nextTick(() => {
        this.resizeTextArea();
      })
    },
    handleEnterPress(event) {
      if (event.shiftKey) {
        return;
      }

      event.preventDefault();
      const defaultChatMethod = this.settings.defaultChatMethod;
      if (defaultChatMethod == 'Secure') {
        this.sendTextMessage(true);
      } else if (defaultChatMethod == 'Insecure') {
        this.sendTextMessage(false);
      } else {
        this.openSendOptions();
      }
    },
    isInMobile() {
      return window.innerWidth < 1000;
    },
    onClickPage(event) {
      const target = event.target;

      const chatInfoRef = this.$refs.chatInfo;
      if (!chatInfoRef || !chatInfoRef.contains(target)) {
        this.setShowingProfileDropdown(false);
      }
    },
  },
  components: { PatientProfileDropdown, ChatMessageRow },
  emits: ['openModal', 'closeModal', 'onChatCreated']
}
</script>

<style scoped>
.panel {
  flex: 1;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  gap: 0;
}

.panel-header {
  padding: 15px;
  box-shadow: 0px 0px 8px #00000022;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.chat-icon-holder {
  position: relative;
}

.chat-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #EEE;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chat-icon img {
  display: block;
  width: 20px;
  height: 20px;
}

.chat-name {
  font-weight: bold;
}

.chat-label {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.panel-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
  overscroll-behavior: none;
}

#messages {
  width: 100%;
  padding: 15px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.panel-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 15px;
  background: #FFF;
  box-shadow: 0px 0px 8px #00000022;
  gap: 10px;
}

.panel-footer .button {
  flex-shrink: 0;
  height: 43px;
}

.panel-footer textarea {
  resize: none;
  overflow: hidden;
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.loading-status span {
  text-align: center;
  padding: 10px;
  background: #e0efff;
  color: #007eff;
  border-radius: 10px;
}

.loading-icon {
  display: block;
  height: 100%;
}

.button img {
  height: 21px;
  display: block;
}

.send-options {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  /* Adjust spacing as needed */
}

#send {
  position: relative;
}

#send img {
  height: 22px;
  display: block;
  filter: invert(1);
}

#send .send-popup {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 10px;
}

#send .send-popup span {
  display: flex;
  align-items: center;
  gap: 8px;
  text-wrap: nowrap;
  background: var(--color-primary);
  color: #FFF;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid #FFF;
  box-shadow: 0px 0px 8px #00000022;
}

.mark-resolved {
  position: relative;
  padding-right: 30px;
}

.unresolved-icon {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 10px;
  width: 10px;
  height: 10px;
  background: #6d3cff;
  border-radius: 50%;
  z-index: 100;
}

.lock-icon {
  height: 16px !important;
  display: block;
  filter: invert(1);
}
</style>