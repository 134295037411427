<template>
  <div class="chat-message-text" :data-from-self-side="fromSelfSide" :style="{maxWidth: getMaxWidth() + 'px'}">
    <span class="message-text">{{ textContent }}</span>
  </div>
</template>

<script>
export default {
  name: 'ChatMessageText',
  props: {
    textContent: String,
    fromSelfSide: Boolean,
    chatWidth: Number
  },
  methods: {
    getMaxWidth() {
      return Math.min(600, this.chatWidth * 0.66);
    }
  }
}
</script>

<style scoped>
.chat-message-text {
  padding: 12px;
  border-radius: 18px;
  background: #EEE;
  min-height: 48px;
  line-height: 24px;
}

.chat-message-text[data-from-self-side="true"] {
  border-bottom-right-radius: 5px;
  background: var(--color-primary);
  color: #FFF;
}

.chat-message-text[data-from-self-side="false"] {
  border-bottom-left-radius: 5px;
}
</style>