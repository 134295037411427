<template>
  <div id="full">
    <OfficePatientChatPanel ref="panel" :chatInfo="chatInfo" :settings="settings" @openModal="openModal" @closeModal="closeModal"
      @onChatCreated="handleChatCreated" />
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <transition name="modal-pop" mode="out-in">
          <UploadFilesModal v-if="modal.id == 'upload-files'" @closeModal="closeModal" :chatInfo="chatInfo" @onFileUploaded="handleFileUploaded" />
          <SelectTemplateModal v-else-if="modal.id == 'select-template'" :chatInfo="chatInfo" @closeModal="closeModal"
            @selectTemplate="handleSelectTemplate" @openModal="openModal" />
          <CreateTemplateModal v-else-if="modal.id == 'create-template'" @closeModal="closeModal"
            @selectTemplate="handleSelectTemplate" />
          <EditTemplateModal v-else-if="modal.id == 'edit-template'" @closeModal="closeModal"
            :template="modal.data.template" />
          <PopulateTemplateModal v-else-if="modal.id == 'populate-template'" :text="modal.data.text"
            @selectTemplate="handleSelectTemplate" @closeModal="closeModal" />
          <SyncPatientModal v-else-if="modal.id == 'sync-patient'" @onPatientImported="handlePatientImported" />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { fetchPatientChatInfoForPatient, fetchPatient, getMessagingSettings, useOneTimeCode } from '@/api'
import * as socket from '../socket.js'

import OfficePatientChatPanel from '../components/officepatientchat/OfficePatientChatPanel.vue'
import UploadFilesModal from '../modals/UploadFilesModal.vue'
import SelectTemplateModal from '@/modals/SelectTemplateModal.vue'
import CreateTemplateModal from '@/modals/CreateTemplateModal.vue'
import PopulateTemplateModal from '@/modals/PopulateTemplateModal.vue'
import EditTemplateModal from '@/modals/EditTemplateModal.vue'
import SyncPatientModal from '../modals/SyncPatientModal.vue'
import { emrToPatient } from '../../../core/src/api/index.js'

export default {
  name: 'OfficePatientChatEmbedView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      chatInfo: null,
      settings: {}
    }
  },
  async mounted() {
    // If they provided a one time code, try to auth
    const oneTimeCode = this.$route.query.otc;
    if (oneTimeCode) {
      await useOneTimeCode(oneTimeCode);
    }

    // Try to find patient with this EMR integration ID
    const emrId = this.$route.params.emrId;
    const {patientExists, patientId} = await emrToPatient(emrId);
    if (patientExists) {
      // If they exist, load it
      this.loadChatInfo(patientId);
    } else {
      // If not, prompt user to import
      this.openModal('sync-patient')
    }

    // Setup listeners
    this.listenForUpdates();
    this.attemptLoadSettings();
    socket.addListener('chat-update-event', this.handleUpdateEvent);
  },
  beforeUnmount() {
    socket.removeListener('chat-update-event', this.handleUpdateEvent);
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      if (this.modal.id == 'sync-patient') {
        return;
      }

      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleChatCreated(chat) {
      this.chatInfo = chat;
    },
    handleFileUploaded(file, errorCallback) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.handleFileUploaded(file, errorCallback);
    },
    handlePatientImported(patientId) {
      this.loadChatInfo(patientId);
      this.closeModal();
    },
    handleSelectTemplate(text) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.setText(text);
      this.closeModal();
    },
    async loadChatInfo(patientId) {
      try {
        const chatInfo = await fetchPatientChatInfoForPatient(patientId);
        if (chatInfo) {
          // Got a chat, lets show it
          this.chatInfo = chatInfo;
        } else {
          // Got a 404, set up a dummy patient chat
          const patient = await fetchPatient(patientId);
          this.chatInfo = { patient }
        }

      } catch (err) {
        console.error('Failed to fetch chat info', err.message);
      }
    },
    async handleUpdateEvent(data) {
      const { chatId } = data;
      console.log('Updating chat', data);

      if (!this.chatInfo || this.chatInfo.id != chatId) {
        console.info('Ignoring....');
        return; // Not current chat, ignore
      }

      try {
        // Refetch chat (in case there's some update to it)
        const chat = await fetchPatientChatInfoForPatient(this.chatInfo.patient.id);
        this.chatInfo = chat;
        console.info('Updated chat: ', chat);

      } catch (err) {
        console.error('Failed to fetch chat info for updated chat', err);
      }
    },
    async listenForUpdates() {
      await socket.connectToSocket();
      console.info('Connected to socket');

      await socket.joinAccountRoom();
      console.info('Joined account room');
    },
    async attemptLoadSettings() {
      try {
        this.settings = await getMessagingSettings();
      } catch (err) {
        console.error(err);
      }
    },
  },
  components: { OfficePatientChatPanel, CreateTemplateModal, UploadFilesModal, SelectTemplateModal, PopulateTemplateModal, EditTemplateModal, SyncPatientModal }
}
</script>

<style scoped>
#full {
  background: #FFF;
}
</style>