<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Select Template</h1>
      <p class="subtitle">
        Select or create a message template that you want to chat.
      </p>
    </div>
    <div class="modal-card-body">
      <input type="text" class="form-control" placeholder="Search templates..." autocomplete="off"
        data-lpignore="true" data-form-type="other" v-model="query" @input="handleQueryInput" ref="search" />
      <div class="template-list" ref="list">
        <div class="template-list-item" v-for="template of templatesToShow" :key="template.id"
          @click="selectTemplate(template)">
          <div class="space-between">
            <h3>{{ template.name }}</h3>
            <div class="action-icons">
              <img src="/icons/edit.png" alt="Edit Template" class="template-action-icon"
                @click.stop.prevent="attemptEditTemplate(template)">
              <img src="/icons/trash.png" alt="Delete Template" class="template-action-icon"
                @click.stop.prevent="attemptDeleteTemplate(template)">
            </div>
          </div>
          <span class="template-text">
            <span v-for="(part, idx) of getTextParts(template.text)" :key="idx">
              <span v-if="/^{.+}$/.test(part)" class="variable">{{ part.substring(1, part.length - 1) }}</span>
              <span v-else>{{ part }}</span>
            </span>
          </span>
        </div>
        <div class="template-list-item new-template" @click.prevent="$emit('openModal', 'create-template')">
          <div class="template-icon">
            <img src="/icons/add.png" alt="Add Template">
          </div>
          <div class="patient-description">
            <span>Create new template</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchTemplates, deleteTemplate } from '@/api';

const VARIABLE_REGEX = /{[^}]+}/g
const QUERY_DELAY = 500

export default {
  name: "SelectTemplateModal",
  props: {
    chatInfo: Object
  },
  data() {
    return {
      query: "",
      templates: [],
      templatesToShow: [],
      timeout: null
    };
  },
  mounted() {
    this.loadTemplates();

    this.$nextTick(() => {
      const element = this.$refs.search;
      element.focus();
    })
  },
  methods: {
    async attemptDeleteTemplate(template) {
      if (!confirm(`Are you sure you want to delete template: ${template.name}?`)) {
        return;
      }

      try {
        await deleteTemplate(template.id);
        this.templates = this.templates.filter(tp => {
          return tp.id != template.id;
        })

      } catch (err) {
        console.error(err);
        alert('Failed to delete template');
      }
    },
    async loadTemplates() {
      try {
        const templates = await fetchTemplates();
        this.templates = templates;
        this.templatesToShow = templates;
      } catch (err) {
        console.error('Failed to fetch template', err);
      }
    },
    handleQueryInput() {
      // If theres a timeout, clear it
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        let query = this.query.toLowerCase();
        query = query.replace(/[^a-z0-9]/g, '');

        this.templatesToShow = this.templates.filter(template => {
          let name = template.name.toLowerCase();
          name = name.replace(/[^a-z0-9]/g, '');

          let text = template.text.toLowerCase();
          text = text.replace(/[^a-z0-9]/g, '');

          if (name.indexOf(query) >= 0) return true;
          if (text.indexOf(query) >= 0) return true;
        })
      }, QUERY_DELAY);
    },
    attemptEditTemplate(template) {
      this.$emit('openModal', 'edit-template', { template })
    },
    selectTemplate(template) {
      let text = template.text;

      // Hard replace rules
      const patient = this.chatInfo.patient;

      text = text.replace(/{First Name}/g, patient.firstName)
      text = text.replace(/{Middle Name}/g, patient.middleName)
      text = text.replace(/{Last Name}/g, patient.lastName)
      text = text.replace(/{Date of Birth}/g, patient.dateOfBirth)
      text = text.replace(/{Email Address}/g, patient.emailAddress)
      text = text.replace(/{Phone Number}/g, patient.phoneNumber)

      if (/{.+}/.test(text)) {
        // More variables, open modal to fill
        this.$emit('openModal', 'populate-template', { text });
      } else {
        // No more variables
        this.$emit('selectTemplate', text);
      }
    },
    getTextParts(text) {
      let parts = [];

      let indexVar = text.search(VARIABLE_REGEX);
      while (indexVar > 0) {
        // If there is a variable, push previous part
        const prevText = text.substring(0, indexVar);
        parts.push(prevText);
        text = text.substring(indexVar);

        // Find where the variable ends, push it
        const endOfVar = text.search(/}/) + 1;
        const varText = text.substring(0, endOfVar);
        parts.push(varText);
        text = text.substring(endOfVar);

        // Check for another variable
        indexVar = text.search(VARIABLE_REGEX);
      }

      // Push remaining part
      if (text.length > 0) {
        parts.push(text);
      }

      return parts;
    }
  },
  components: {},
  emits: ["openModal", "closeModal", "selectTemplate"],
};
</script>

<style scoped>
.modal-card {
  max-width: 600px;
}

.template-list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 500px;
  overflow-y: auto;
}

.template-list-item {
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
}

.template-list-item:hover {
  background: #F2F2F2;
}

.template-list-item h3 {
  margin-bottom: 10px;
}

.template-list-item p {
  line-height: 24px;
}

.new-template {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px;
}

.new-template .template-icon {
  background: #FFF;
  border: 2px dashed #EEE;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.template-icon img {
  width: 25px;
  height: 25px;
  display: block;
}

.space-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.template-action-icon {
  height: 20px;
  display: block;
  cursor: pointer;
  opacity: 0.5;
}

.template-action-icon:hover {
  opacity: 1;
}

span.variable {
  background: #e0efff;
  border: 1px solid #b1d7ff;
  padding: 1px;
  border-radius: 4px;
}

.template-text {
  line-height: 24px;
}

.action-icons {
  display: flex;
  gap: 10px;
}
</style>