<template>
  <div class="chat-message" :data-from-self-side="fromSelfSide">
    <div class="message-date-strip" v-if="isFirstOfDay">
      <span class="message-date">{{ getMessageDate() }}</span>
    </div>
    <div class="message-content">
      <div class="author-column" v-if="shouldShowPicture()">
        <div class="author-picture" v-if="isFirstOfKind">
          <img :src="profilePicture" class="author-picture" alt="Author Picture">
        </div>
      </div>
      <div class="message-column">
        <div class="message-body">
          <ChatMessageFile :fileContent="message.fileContent" :fromSelfSide="fromSelfSide" :chatWidth="chatWidth"
            v-if="message.fileContent != null" />
          <ChatMessageText :textContent="message.textContent" :fromSelfSide="fromSelfSide" :chatWidth="chatWidth"
            v-else />
          <img src="/icons/dots.png" alt="Actions" class="actions-icon" v-if="hasActions">
        </div>
        <div class="message-footer" v-if="isLastOfKind">
          <span>{{ message.sender.name }} • {{ getMessageTime() }}</span>
          <img src="/icons/lock.png" alt="Lock Icon" class="lock-icon" v-if="message.isSecure">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatMessageText from './ChatMessageText.vue'
import ChatMessageFile from './ChatMessageFile.vue'

export default {
  name: 'ChatMessageRow',
  props: {
    message: Object,
    fromSelfSide: Boolean,
    isFirstOfDay: Boolean,
    isLastOfKind: Boolean,
    isFirstOfKind: Boolean,
    hideSelfPicture: Boolean,
    profilePicture: String,
    hasActions: Boolean,
    chatWidth: Number
  },
  methods: {
    getMessageDate() {
      const date = new Date(this.message.dateCreated);
      const today = new Date();
      const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day

      // Check if the date is today
      if (date.toLocaleDateString() === today.toLocaleDateString()) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
        return "Yesterday";
      }

      // Check if the date is within the last week
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[date.getDay()];
      if (today - date < 7 * oneDay) {
        return dayOfWeek;
      }

      // If more than a week ago, return MM/DD/YYYY format
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      return formattedDate;
    },
    getMessageTime() {

      const date = new Date(this.message.dateCreated);

      const time = date.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true // Use true for AM/PM format, false for 24-hour format
      });

      return time;
    },
    shouldShowPicture() {

      if (this.hideSelfPicture && this.fromSelfSide) {
        return false;
      }

      return true;
    }
  },
  components: { ChatMessageText, ChatMessageFile }
}

</script>

<style scoped>
.chat-message {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
}

.message-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.author-column {
  width: 48px;
}

.chat-message[data-from-self-side="true"] .message-content {
  flex-direction: row-reverse;
}

.chat-message[data-from-self-side="true"] .message-column {
  align-items: flex-end;
}

.message-footer {
  font-size: 14px;
  text-wrap: nowrap;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  opacity: 0.5;
}

.author-picture {
  width: 100%;
  border-radius: 50%;
  display: block;
}

.message-date-strip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 50px;
}

.message-date {
  font-size: 14px;
  padding: 10px;
  background: #F5F5F5;
  color: #999;
}

.lock-icon {
  height: 16px;
  display: block;
}

.actions-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.actions-icon {
  height: 24px;
  display: block;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity ease-in-out 0.1s;
}

.actions-icon:hover {
  opacity: 1;
}

.chat-message:not(:hover) .actions-icon {
  opacity: 0;
}

.message-body {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-message[data-from-self-side="true"] .message-body {
  flex-direction: row-reverse;
}
</style>