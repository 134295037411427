<template>
  <div class="modal-card">
    <img src="/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="modal-card-header">
      <h1 class="title">Search Patients</h1>
      <p class="subtitle">
        Please enter the full name, phone number, or email address of the
        patient who you want to chat with.
      </p>
    </div>
    <div class="modal-card-body">
      <input type="text" class="form-control" placeholder="Name, phone number, or email" autocomplete="off"
        data-lpignore="true" data-form-type="other" v-model="query" @input="handleQueryInput" ref="search" />
      <div class="patient-list" ref="list" @scroll="onScroll">
        <div class="min-height-panel" :style="{ height: !doneLoadingPatients && !loading ? 'calc(100% + 10px)' : '' }">
          <PatientListItem v-for="patient of patients" :key="patient.id" :patient="patient" @click="handleSelectPatient(patient)" />
          <div class="new-patient" @mousedown.prevent.stop="handleCreatePatient">
            <div class="new-patient-icon">
              <img src="/icons/add.png" alt="New Patient">
            </div>
            <span>Create new patient</span>
          </div>
          <div class="loading-status" v-if="!doneLoadingPatients">
            <img src="/loading.svg" class="loading-icon" v-if="loading">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchPatients } from "@/api";
import PatientListItem from "@/components/PatientListItem.vue";

const FAKE_LATENCY = 500;
const QUERY_DELAY = 500;
const NAME_REGEX = /^(\s?[A-Za-z\-']+)+$/
const EMAIL_REGEX = /^(.+)@(.+)\.(.{2,})$/
const PHONE_REGEX = /^[0-9]{10}$/

export default {
  name: "StartPatientChatModal",
  data() {
    return {
      query: "",
      timeout: null,
      loading: false,
      error: "",
      patients: [],
      doneLoadingPatients: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      const element = this.$refs.search;
      element.focus();
    })

    this.loadLatestPatients();
  },
  methods: {
    async loadLatestPatients() {
      try {
        this.loading = true;
        const { patients, isLast } = await searchPatients(this.query);
        this.patients = patients;
        console.log(this.patients);
        this.doneLoadingPatients = isLast;
      } catch (err) {
        console.error('Failed to load latest patients');
      } finally {
        this.loading = false;
      }
    },
    async loadNextPatients() {
      if (this.loading) {
        return; // Already loading
      }

      try {
        this.loading = true;

        // Fake delay
        await new Promise(resolve => setTimeout(resolve, FAKE_LATENCY));

        // Fetch next set of chats
        const oldestLoadedPatient = this.patients[this.patients.length - 1];
        const { patients, isLast } = await searchPatients(this.query, oldestLoadedPatient.id);

        // Push new chats to list
        this.doneLoadingPatients = isLast;
        for (let patient of patients) {
          this.patients.push(patient);
        }

      } catch (err) {
        console.error('Failed to load latest patients');
      } finally {
        this.loading = false;
      }
    },
    handleQueryInput() {
      // If theres a timeout, clear it
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.loadLatestPatients();
      }, QUERY_DELAY);
    },
    handleSelectPatient(patient) {
      this.$emit('closeModal');

      setTimeout(() => {
        this.$router.push(`/patients/` + patient.id);
      }, 100)
    },
    handleCreatePatient() {
      // Is format is email -> copy email over
      if (EMAIL_REGEX.test(this.query)) {
        this.$emit('openModal', 'create-patient', { emailAddress: this.query });
        return;
      }

      // Is format is phone -> copy phone over
      const digits = this.query.replace(/[^0-9]/g, '');
      if (PHONE_REGEX.test(digits)) {
        this.$emit('openModal', 'create-patient', { phoneNumber: digits });
        return;
      }

      // If format is full name -> copy names over
      if (NAME_REGEX.test(this.query)) {
        const tokens = this.query.split(/\s+/);
        let firstName = tokens[0];

        let lastName = '';
        if (tokens.length > 1) {
          lastName = tokens[tokens.length - 1];
        }

        let middleName = '';
        if (tokens.length > 2) {
          middleName = tokens.slice(1, tokens.length - 1).join(' ');
        }

        this.$emit('openModal', 'create-patient', { firstName, middleName, lastName });
        return;
      }

      this.$emit('openModal', 'create-patient', {});
    },
    onScroll(event) {
      if (this.doneLoadingPatients) {
        return; // Previous fetch returned 0 patients
      }

      const list = event.target;
      const maxScroll = list.scrollHeight - list.offsetHeight;
      const percentScrolled = list.scrollTop / maxScroll;

      // Are we at bottom? Try to fetch chats
      if (percentScrolled >= 1) {
        this.loadNextPatients();
      }
    },
  },
  components: {PatientListItem},
  emits: ["openModal", "closeModal"],
};
</script>

<style scoped>
.modal-card {
  max-width: 600px;
}

.patient-list {
  height: 1px;
  min-height: 1px;
  overflow: hidden;
  height: 300px;
  margin-top: 15px;
  overflow-y: auto;
}

.list-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.new-patient-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #FFF;
  border: 2px dashed #EEE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-patient-icon img {
  width: 20px;
  height: 20px;
  display: block;
}

.new-patient {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px;
}

.new-patient:hover {
  background: var(--color-fill-med);
}

.loading-status {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  display: block;
  height: 100%;
}

.loading-done {
  color: #CCC;
}
</style>