<template>
  <div id="full">
    <PatientChatPanel ref="panel" :chatInfo="chatInfo" @openModal="openModal" @closeModal="closeModal" />
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <transition name="modal-pop" mode="out-in">
          <AuthPatientModal v-if="modal.id == 'auth-patient'" @patientLogin="handlePatientLogin"/>
          <UploadFilesModal v-else-if="modal.id == 'upload-files'" @closeModal="closeModal" :chatInfo="chatInfo" @onFileUploaded="handleFileUploaded" />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { fetchPatientChatInfoForPrivateUrl } from '@/api'
import PatientChatPanel from '../components/patientchat/PatientChatPanel.vue'
import AuthPatientModal from '../modals/AuthPatientModal.vue'
import UploadFilesModal from '@/modals/UploadFilesModal.vue'
import * as socket from '@/socket.js'

export default {
  name: 'PatientChatView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },
      chatInfo: null
    }
  },
  mounted() {
    const params = this.$route.params;
    this.loadChatInfo(params.url);

    document.documentElement.style.overscrollBehavior = 'none';
    document.body.style.overscrollBehavior = 'none';
  },
  methods: {
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {

      // Special case: auth modal
      if (this.modal.id == 'auth-patient') {
        return;
      }

      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handlePatientLogin() {
      const params = this.$route.params;
      this.loadChatInfo(params.url);
      this.closeModal();
    },
    handleFileUploaded(file, errorCallback) {
      const panel = this.$refs.panel;
      if (!panel) return;
      panel.handleFileUploaded(file, errorCallback);
    },
    async loadChatInfo(privateUrl) {
      try {
        const chatInfo = await fetchPatientChatInfoForPrivateUrl(privateUrl);

        if (!chatInfo) {
          console.info('Patient is not authenticated');
          this.openModal('auth-patient');
          return;
        }

        this.chatInfo = chatInfo;
        this.listenForUpdates();
      } catch (err) {
        console.error('Failed to fetch chat info', err.message);
        alert('Failed to fetch chat info');
      }
    },
    async listenForUpdates() {
      await socket.connectToSocket();
      console.info('Connected to socket');

      const patient = this.chatInfo.patient;
      await socket.joinPatientRoom(patient.id);
      console.info('Joined patient room');
    }
  },
  components: { PatientChatPanel, AuthPatientModal, UploadFilesModal }
}
</script>

<style scoped>

#full {
  height: 100dvh;
  background: #F5F5F5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-background {
  padding: 5px;
}

</style>